import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Box, Text, Heading, Flex } from '@chakra-ui/react'

import Seo from "../components/seo"


const AboutUs = () => (
  <>
    <Seo title="About New West Properties" />
    <Container my={{sm: '8rem', md: '12rem' }} maxW="container.lg">
      <Flex h="100%">
        <Box>
        <Flex flexWrap="wrap-reverse" flexDirection="row">
        <Box w={{sm: '100%', md: '40%'}}>
          <StaticImage 
            src="../images/allen-leah.jpg"
            alt="Allen and Lea"
            placeholder="blurred"
            layout="fullWidth"
          />
        </Box>
      <Box w={{ sm: '100%', md: '60%'}}>
        <Heading as="h1" px="8" color="pine.300" textTransform="uppercase" fontSize="3rem" lineHeight="0.8" >
          About Us
        </Heading>
        <Box p="8"> 
        <Text>
        Welcome to New West Properties. We’re in love with the deserts, mountains and coast of the Western US, and since then we’ve dedicated our company to allowing others to develop the same love for these landscape and the people that live in them.</Text>
        <Text>We have properties in the Utah deserts, Wine Country of California and Teton Mountains. Throughouth all of our locations, we’ve worked incredibly hard to make sure that these are spaces that will feel like home, but also inspire you and your group to go out and explore these majestic areas.</Text>
        <Text>We’re committed to making every element of your stay memorable, convenient and comfortable. If there’s ever anything we can do to make your stay better, please let us know.</Text>
        <Text><strong>ALLEN &amp; LEA THOMAS</strong><br/>
        Founders, New West Properties
        </Text>
        </Box>
      </Box>
      </Flex>
        </Box>
      </Flex>
    </Container>
  </>
)

export default AboutUs
